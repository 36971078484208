<template>
    <div class="card bs-5 mb-5">

        <div class="fl-te-c pb-3">
            <h4>TEAM SOCIAL MEDIA</h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.siteMapAddModal.show()" text="Add"/>
            </div>
        </div>

        <custom-vue-table :fields="fields" :url="listUrl" :per-page="10" ref="table" class="d-sm-none-lg-block" :extra-params="{team}">
            <template #is_enabled="{rowData}">
                <p v-if="rowData.is_enabled">Yes</p>
                <p v-else>No</p>
            </template>
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <edit-btn icon="fa fa-eye" text="View" @click="viewDetails(rowData.id)"/>

                    <delete-btn design="basic" @click="setDelete(rowData)"/>
                </div>
            </template>
        </custom-vue-table>
        <team-social-media-mobile-view @viewDetails="viewDetails" @setDelete="setDelete"
                                    class="d-lg-none"></team-social-media-mobile-view>

        <modal title="Add Team Social Media" ref="siteMapAddModal" width="40r" :no-close-on-backdrop="true" header-color="primary">
            <AddTeamSocialMedia :team="team" @success="formSuccess"></AddTeamSocialMedia>
        </modal>

        <modal title="Edit Team Social Media" ref="siteMapEditModal" :no-close-on-backdrop="true" width="40r" header-color="primary">
            <EditTeamSocialMedia :initial-data="editingItem" @success="formSuccess"></EditTeamSocialMedia>
        </modal>

        <delete-modal ref="siteMapDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the Team Social Media  <b v-html="deletingItem && deletingItem.name"></b>. Are you sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '@/data/cms-urls';
import AddTeamSocialMedia from './AddTeamSocialMedia';
import EditTeamSocialMedia from './EditTeamSocialMedia';
import TeamSocialMediaMobileView from '@/views/cms/our-team/team/TeamSocialMediaMobileView';

export default {
    name       : 'TeamSocialMedia',
    props      : { team : { type : Number } },
    components : { TeamSocialMediaMobileView, AddTeamSocialMedia, EditTeamSocialMedia },

    data () {
        return {
            listUrl   : urls.cms.teamSocialMedia.list,
            deleteUrl : urls.cms.teamSocialMedia.delete,

            editingItem  : null,
            deletingItem : null,

            fields : [
                {
                    name      : 'name',
                    sortField : '',
                    title     : 'Site Name'
                },
                {
                    name      : 'site_map_type',
                    sortField : '',
                    title     : 'Social media type'
                },
                {
                    name      : 'created_at',
                    sortField : 'created_at',
                    title     : 'Created At'
                },
                {
                    name  : '__slot:is_enabled',
                    title : 'Enabled'
                },
                {
                    name       : '__slot:actions',
                    title      : 'Actions',
                    titleClass : 'center bg-gray aligned text-right',
                    dataClass  : 'center aligned text-right'
                }
            ]
        };
    },
    methods : {
        viewFile (file) {
            window.open(file);
        },
        viewDetails (id) {
            this.$router.push('/cms/team-social-media/' + id + '/details/');
        },
        formSuccess () {
            const refs = this.$refs;
            refs.siteMapAddModal.close();
            refs.siteMapEditModal.close();
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.editingItem.team = this.team;
            this.$refs.siteMapEditModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.siteMapDeleteModal.show();
        },

        deleteComplete (response) {
            if (response.error === true) {
                if (response.message) {
                    this.$notify(response.message, 'Failed', { type : 'danger' });
                } else {
                    this.$notify('Failed to delete, Please try again later', 'Failed',
                        { type : 'danger' }
                    );
                }
            } else {
                this.$notify('Successfully Deleted!', 'Success', { type : 'success' }
                );
            }
            this.deletingItem = null;
            this.$refs.siteMapDeleteModal.close();
            this.$refs.table.refreshTable();
        }

    }
};
</script>

<style scoped>

</style>
