<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}" :save-params="{team}">
        <div class="row">
            <div class="col-12">
                <validated-input label="Name" v-model="model.name"
                                 :rules="{required: true, max:10}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <validated-vue-select :options="typeOptions" label="Social Media Type"
                                      v-model="model.site_map_type"
                                      :rules="{required: true}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <validated-input label="Website Link" name="website_link" v-model="model.website_link"
                                 :rules="{required: true}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-checkbox label="Enabled" v-model="model.is_enabled"
                                :rules="{required: false}" :disabled="loading"/>
            </div>
            <div class="col-12 text-right">
                <btn class="trn" size="sm" :loading="loading" color="primary" text="Save"

                     loading-text="Saving..." :disabled="loading"/>
            </div>
        </div>
    </b-form>
</template>

<script>
import urls from '@/data/cms-urls';

export default {
    name  : 'AddTeamSocialMedia',
    props : { team : { type : Number } },
    data () {
        return {
            addUrl      : urls.cms.teamSocialMedia.addEdit,
            typeOptions : [
                { label : 'Facebook', value : 'Facebook' },
                { label : 'Instagram', value : 'Instagram' },
                { label : 'Twitter', value : 'Twitter' },
                { label : 'WhatsApp', value : 'WhatsApp' },
                { label : 'LinkedIn', value : 'LinkedIn' },
                { label : 'Youtube', value : 'Youtube' },
                { label : 'WeChat', value : 'WeChat' },
                { label : 'Sina Weibo', value : 'Sina Weibo' },
                { label : 'QQ', value : 'QQ' },
                { label : 'Telegram', value : 'Telegram' },
                { label : 'Pinterest', value : 'Pinterest' },
                { label : 'SnapChat', value : 'SnapChat' },
                { label : 'Reddit', value : 'Reddit' },
                { label : 'Quora', value : 'Quora' },
                { label : 'Skype', value : 'Skype' },
                { label : 'Microsoft Teams', value : 'Microsoft Teams' }
            ]
        };
    },

    methods : {
        formSuccess () {
            this.$notify('Successfully Added Team Social Media..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
